import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/App';
import Cheats from './pages/Cheats';
import Products from './pages/Products';
import Product from './pages/Product';
import Faq from './pages/Faq';
import Discord from './pages/Discord';
import Status from './pages/Status';
import Tos from './pages/Tos';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="cheats" element={<Cheats />} />
          <Route path="products/:id" element={<Products />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="faq" element={<Faq />} />
          <Route path="discord" element={<Discord />} />
          <Route path="status" element={<Status />} />
          <Route path="tos" element={<Tos />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
reportWebVitals();
