import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  Cog6ToothIcon,
  LockClosedIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ShoppingCartIcon,
  BoltIcon,
  ClockIcon,
  UserGroupIcon,
  StarIcon
} from '@heroicons/react/20/solid'


const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]

const features = [
  {
    name: 'Affordable',
    description: 'Here at reported we strive to have the cheapest possible prices on the market to ensure that it is affordable for everyone to enjoy.',
    icon: BanknotesIcon,
  },
  {
    name: 'Trustworthy',
    description: 'We aim to be as reputable as possible in order to ensure that the customer has the best possible experience. ',
    icon: LockClosedIcon,
  },
  {
    name: 'Dedicated support',
    description: 'We want to make sure that customers gets the help that they deserve by checking support tickets on a daily basis to fix any needs. ',
    icon: UserGroupIcon,
  },
  {
    name: 'Undetection',
    description: 'We want to make sure that your account is fully safe at all times, so we provide nothing but the best cheats possible.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Frequent updates',
    description: 'Our developer teams ensure that the product is consistently updated and there is as little down time as possible.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Fast purchasing',
    description: 'We want to ensure that you get what you paid for as well as getting it in a very efficient manner.',
    icon: ShoppingCartIcon,
  },
]

const supportLinks = [
  {
    name: 'The extra edge',
    to: '/cheats',
    description:
      'Reported R6 provides you with the best possible features to ensure that you have an extra edge over other players. Even if you are just using ESP with visible check, you can make sure that you are advantageous without risking a ban. The change in colors from invisible to visible also makes sure that you are not going to shoot people through a wall accidentally, but still allows you to have sufficient situational awareness.',
    icon: BoltIcon,
  },
  {
    name: 'Easy to use',
    to: '/cheats',
    description:
      'Do you hate long and boring processes? Do you hate the fact that your day key is ticking and you cannot do anything because the setup is hard? Well this will not be a problem with Reported R6. This cheat has a max installation process time of a couple of mins. To make sure that you get your full length in the day key. We have a super fast support team too! Though, you wont need help because its super simple to setup.',
    icon: ClockIcon,
  },
  {
    name: 'Very fun',
    to: '/cheats',
    description:
      'I am sure you also hate dying to people holding god angles. Or even dying because some Ash sweat who just prefired you when there was no chance he knew where you were? Well Reported R6 allows you to combat back against these cheaters while as well, to have your account hit Champion (oh yes we have had 14 users hit that rank). Crazy, isnt it? That is Reported R6.',
    icon: UserGroupIcon,
  },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div>
      <div className="isolate bg-black">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <main>
          <div className="relative py-6 sm:py-8 lg:pb-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="relative py-0 sm:py-0 lg:pb-0">
  <div className="mx-auto max-w-7xl px-0 lg:px-0" style={{ display: 'flex', justifyContent: 'center' }}>
    <img
      src="/banner.gif"
      alt="Gif"
      style={{ maxWidth: '500px', height: 'auto', borderRadius: '4px' }} // Adjust the maxWidth to the size you want your GIF to be
    />
  </div>
</div>

              <div className="mx-auto max-w-2xl text-center">
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Need Assistance? Cant Stop Losing? Want That Extra Edge Over Other Players?
                </p>
                <p className="mt-1 text-lg leading-8 text-gray-300">
                  We Got You Covered! 
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                    to="/cheats"
                    className="rounded-md bg-fuchsia-500 px-3.5 py-1.5 text-base font-semibold leading-9 text-white shadow-sm hover:bg-fuchsia-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-500"
                  >
Browse cheats now!                </Link>
                </div>
              </div>
              <img
                src="/VISCHECKFRONT.png"
                alt="Reported R6"
                width={2432}
                height={1442}
                className="mt-16 rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-24"
              />
          <section
            className="relative z-10 mx-auto -mt-32 max-w-md px-6 sm:max-w-3xl lg:max-w-7xl lg:px-8"
            aria-labelledby="contact-heading"
          >
            <h2 className="sr-only" id="contact-heading">
Buy Reported EFT            </h2>
            <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8 mt-12">
              {supportLinks.map((link) => (
                <div key={link.name} className="flex flex-col rounded-2xl bg-black shadow-xl">
                  <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                    <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-fuchsia-500 p-5 shadow-lg">
                      <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <h3 className="text-xl font-medium text-white">{link.name}</h3>
                    <p className="mt-4 text-base text-gray-300">{link.description}</p>
                  </div>
                  <div className="rounded-bl-2xl rounded-br-2xl bg-gray-900 p-6 md:px-8">
                    <Link to={link.to} className="text-base font-medium text-white hover:text-fuchsia-500">
Buy Reported R6                     <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </section>
            </div>
            <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
              <svg
                className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
              >
                <path
                  fill="url(#ee0717bf-3e43-49df-b1bd-de36422ed3d3)"
                  fillOpacity=".2"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="ee0717bf-3e43-49df-b1bd-de36422ed3d3"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset={1} stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </main>
      </div>
      <div className="bg-black py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-fuchsia-500">Our promises</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Why should you shop at reported?</p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Because we actually care. 
          </p>
          <div/>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.icon className="absolute top-1 left-1 h-5 w-5 text-fuchsia-500" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-300">
            &copy; 2024 Reported Cheats. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    </div>
  )
}
