import { useState } from 'react'
import { Disclosure, Dialog } from '@headlessui/react'
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]


const faqs = [
    {
      question: "Can I use any other OS besides Windows?",
      answer:
        "No, our products only work for Windows devices. Some of our products work on only Windows 10 too, so make sure to check the product description. ",
    },
    {
      question: "How do I get the product?",
      answer:
        "Everything will be delivered to your email. Make sure to check your spam folder. ",
    },
    {
      question: "Can I get a refund?",
      answer:
        "No, we do not offer refunds.",
    },
    {
      question: "What payment methods are accepted?",
      answer:
        "We accept Cashapp, most crypto currencies, and PayPal. Note that PayPal also has an integrated card processor in case you only have a debit/credit card. ",
    },
    {
      question: "Where do I go if I need help?",
      answer:
        "Make a ticket in discord.gg/reported or check the Discord page",
    },
    {
      question: "What do I do if I am blacklisted because I made a dispute?",
      answer:
        `You will need to pay a fine in order to be unblacklisted from our services. The rates are $40 for your first offense, $75 for your second, $150 for your third, and any further offenses will be $350 and up. The way you can get blacklisted is from Charging back or disputing, Breaking any sort of rules in our TOS, Being unnecessarily toxic or offensive. There are more ways to get blacklisted. Just use your brain and think before you do.`,
    },
]

  
export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div>
      <div className="isolate bg-black">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
          </div>
        </div>
      </div>
      <footer className="bg-black">
      <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-300">
            &copy; 2024 Reported Cheats, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    </div>
  )
}
