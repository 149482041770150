import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Link, useParams } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]

        
const products = [
  {
    id: 168608,
    name: 'EFT Private',
    category: 'EFT',
    to: '/Product/168608',
    price: '$9.99',
    imageSrc: '/privvy.png',
    desc:
      `▸ CPU: INTEL and AMD
      ▸ Win Ver: Win10 2004+ through Win11 22H2
      
      Aimbot
      
      ▸ Silent aim
      ▸ FOV adjustment
      ▸ Multiple aimbot keys
      ▸ Aimbot prediction
      ▸ Vis check
      
      Player ESP
      
      ▸ Teammate check
      ▸ Skeleton
      ▸ Health
      ▸ Distance
      ▸ SCAV filter
      ▸ Corpse
      ▸ Level
      
      Item ESP
      
      ▸ Boxes
      ▸ Valuables
      ▸ Quest items
      ▸ Containers
      ▸ Boxes
      ▸ All loot
      ▸ see loot inside containers
      
      Misc
      
      ▸ Third person
      ▸ No visor
      ▸ Thermal vision (custom colored)
      ▸ No spread
      ▸ Streamer mode (turns all blantant features off)
      ▸ Aspect ratio changer
      ▸ Night vision
      
      Exploits
      
      ▸ Instant bullet (risky)
      ▸ Instant search (safe)
      ▸ Instant examine (safe)
      ▸ Loot through walls (safe)`,
  },
  {
    id: 168609,
    name: 'Plus EFT',
    category: 'EFT',
    to: '/Product/168609',
    price: '$7.49',
    imageSrc: '/plussy.png',
    desc:
      `Reported EFT glow | Best legit cheat

      Requirements:
      
      [+] AMD / INTEL CPU
      [+] ALL WINDOWS 10 VERSIONS
      [+] UNIQUE BUILD GENERATION
      
      Features:
      
      [+] Player chams
      [+] Visible check
      [+] No recoil
      [+] No sway
      [+] Infinite stamina
      [+] Double search`,
    status: 'Undetected'
  },
  /*
  {
    id: 39770,
    name: 'KD Dropper',
    category: 'EFT',
    to: '/Product/39770',
    price: '$19.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/n3MEkKWv2n9MMi5jyWkY5TuPOYBSS889oWmaGPhJ.png',
    desc:
      `This program lowers your KDR drastically

      Why is this a thing?
      
      If your KD is too high you will get manual banned. This ensures longevity of your account. `,
    status: 'Undetected'
  },
 /* {
    id: 59919,
    name: 'Labs Kings EFT',
    category: 'EFT',
    to: '/Product/59919', 
    price: '$11.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/qg8pEkGQHCdLPAf2CTCHSqqYUcjWTIAgKZivhgSK.jpg',
    desc:
      `System Requirements:
      CPU: Intel / AMD;
      OS: Windows 7,8,10 & 11
      Windows Version: All
      Display: Boarderless, Windowed
      
      Not Compatible with hwidspoofer.com
      
      
      [Aimbot]
      [+] Silent Aimbot
      [+] Aimbot Keybind
      [+] Bullet Drop Correction
      [+] Movement Prediction
      [+] Velocity Adjustment
      [+] Velocity History
      [+] Hitbox Cycle (Keybinded)
      
      [Player Visuals]
      [+] Player Box
      [+] Player Cross
      [+] Player Skeleton
      [+] Player Name
      [+] Player KD
      [+] Player Level
      [+] Player Item in Hands
      [+] Player Health
      [+] Player Total Inventory worth
      [+] Player Inventory Display
      [+] Player Distance
      [+] Player Quest Item Indicator
      [+] Player Custom Item Indicator
      [+] ^^All that are Applicable also applies to Bosses and Scavs too^^
      
      [World Visuals]
      [+] 2D Radar: Customizable Size and Scale
      [+] Landmine Warning Indicator
      [+] Sniper Zone ESP
      [+] Quest Items and Locations
      [+] Extract ESP
      [+] Shows requirements for extracts
      [+] Shows where requirements are: Names and Locations of power switches etc
      [+] Filters out extracts that you cannot use
      [+] Displays Items you need for active quests
      [+] Displays Locations you need for active quests
      [+] Extremely tuned Quest ESP
      
      [Loot Visuals]
      [+] Live Market data and prices
      [+] Option to change item prices: Live, 24hr Average, 7Day Average or Trader Price
      [+] Smart system displays correct Item Pricing
      [+] Anti Clutter System: Only displays Item information when hovered
      [+] Min price for nearby ground items
      [+] Min price for nearby containers
      [+] Min price for expensive global containers and items
      [+] Pixel and FOV Hover limit
      [+] Option to Center or Align Container Loot information
      [+] Highest Valuable Item on Map Visual
      [+] Player who Holds Highest Item Indicator
      [+] Custom Item Filter
      
      [Screen Indicators]
      [+] Watermark toggle: ‘fanta’
      [+] Execution Time: Overlay FPS, Code execution timing for cheat and rendering in milliseconds
      [+] Timescale
      [+] Aimbot targeted bone
      [+] Player count
      [+] Bot count
      [+] Will display which Body Part was hit on the enemy when you shoot them
      [+] Highest item name and price
      [+] 2D Radar: Squares for bots, Directional triangles for players
      [+] Killfeed
      [+] Playerlist
      [+] Local Player Info
      [+] List of who has extracted/when they extract
      
      [Misc]
      [+] No Recoil
      [+] No Breath
      [+] No Stamina
      [+] Thermal Vision
      [+] Night Vision
      [+] No Visor
      [+] Loot Through Walls
      [+] Crosshair
      [+] Time of Day changer
      [+] Bullet ESP
      [+] Skeleton Display Range Ratio
      [+] Loot Distance
      [+] Hotkeys for Virtually Everything
      [+] Colour Selection for Virtually Everything`,
    status: 'Undetected'
  }, 
  */
  {
    id: 168610,
    name: 'Terra ABS EFT',
    category: 'EFT',
    to: '/Product/168610',
    price: '$9.99',
    imageSrc: '/abs.png',
    desc:
      `[AIM]

      [+]AimLock – Locks onto enemy model.
      [+]BulletTrack – Bullets hit head.
      [+]Target Lock – Locks onto specific enemy
      
      [ESP]
      
      [+]Player ESP – Show players.
      [+]Name ESP – Show player names.
      [+]2D Box – Frames around players.
      [+]Grenade ESP – Show thrown grenades.
      [+]Skeleton – Draw skeleton.
      [+]Show Health.
      [+]Distance ESP – Show distance.
      [+]Show Friendly – Show teammates.
      [+]Smart Visibility – Know if someone has eyes on you or you have eyes on them.
      
      [LOOT ESP]
      
      [+]Show loot.
      [+]Set max show distance.
      [+]Show Containers.
      [+]Show Exits.
      [+]Show all container items.
      [+]Customise what items to see – dedicated website.
      
      [ITEM FILTER]
      
      [+]Show/Hide items by category.
      [+]Hide empty containers.
      [+]Customise through website
      
      [MISC]
      
      [+]Infinite Stamina
      [+]Radar
      [+]Night Vision
      [+]Thermal Vision
      [+]Remove Visor
      [+]Landmines
      [+]Sniper
      [+]Danger Warning – Tells you closest player to you.
      [+]Crosshair
      
      ▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬
      System Requirements:
      CPU: Intel / AMD;
      OS: Windows 11; Windows 10 (1809, 1903, 1909, 2004, 20H2, 21H2);
      ▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬`,
  },
  
  {
    id: 49693,
    name: 'Reported Valorant Lite',
    category: 'Valorant',
    to: '/Product/49693',
    price: '$4.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/nrGDCWjIZ6wj3Ei9xecYvE9ZAsWWWdBU9JYWaSNt.png',
    desc:
      `Reported lite 
      - Supports Windows 10 (1907-22h2)
      
      
      Player Visuals
      - Box
      - Corner Box
      - Skeleton
      - Health
      - Distance
      - Snaplines
      - Player Name
      - Agent Name
      - Health (Number)
      
      MISC
      - ColorChanger accessible to change the colors to most visual features
      - Completely Streamproof`,
    status: 'Undetected'
  },
  
  {
    id: 181122,
    name: 'Rust Private',
    category: 'Rust',
    to: '/Product/181122',
    price: '$39.99',
    imageSrc: '/rustpriv.png',
    desc:
      `Lastcheat Rust
      [ Visuals Tab ]
      
      [+] Player/Scientist ESP
      [+] Show Teammates
      [+] Show Wounded
      [+] Show Sleepers
      [+] Name
      [+] Health/Healthbar
      [+] Distance
      [+] Team Number
      [+] SteamID
      [+] Image Based Hotbar
      [+] Text Based Hotbar
      [+] Aim Direction
      [+] Corner/Full Boxes
      [+] Skeleton
      [+] Snaplines
      [+] Offscreen Indicators
      [+] Chams
      
      [ Aimbot Tab ]
      
      [+] Hotkey
      [+] Smoothing
      [+] Draw FOV
      [+] Keep Target
      [+] Target Teammates
      [+] FOV Slider
      [+] Hitbox Selector
      [+] Ignore List (Players, Sleepers, Wounded, Scientists)
      
      [+] Silent Aim*
      [+] Hotkey
      [+] Draw FOV
      [+] Auto Shoot
      [+] Target Teammates
      [+] Aim Line
      [+] FOV Slider
      [+] Hitbox Selector
      [+] Ignore List (Players, Sleepers, Wounded, Scientists)
      
      Weapon Mods
      [+] Recoil Slider
      [+] Spread Slider
      [+] Sway Slider
      [+] Fire rate Slider
      [+] Thickness Slider
      [+] Bullet Speed Slider
      [+] Heli Hitbox Expander
      [+] Bullet Tracers
      [+] Instant Eoka
      [+] Reload Indicator
      [+] Full Auto
      [+] Shoot Through Teammates
      [+] Shoot Through Wounded
      [+] Penetrate/Ignore Materials(Shoot through certain materials such as cargo ship)
      [+] Weapon Spam(Always On/On Hotkey)
      [+] Custom Hitsound
      [+] Custom Hit Distance
      [+] Custom Hit Box Override
      
      Rage
      [+] Desync
      [+] Desync Hotkey
      [+] Show Desync Indicator
      [+] Stop Movement
      [+] Instant Shoot(Shoots max amount of bullets within the same milisecond)
      [+] Powershot(Waits until max desync then shoots max amount of bullets)
      
      [ Movement ]
      
      [+] Anti-Flyhack Bar
      [+] Omni Sprint
      [+] Can Attack Always
      [+] Can Hold Items Always
      [+] On Ladder Animation
      [+] Walk On Water
      [+] Walk Through Players
      [+] Walk Through Trees
      [+] No Fall Damage
      [+] Infinite Jump
      [+] High Jump
      [+] Spiderman
      [+] Speedhack
      
      Visual Mods
      [+] Bullet Spectate
      [+] Custom Ambient
      [+] Custom Time
      [+] No Weapon Bobbing
      [+] No Weapon Lowering
      [+] No Weapon Lerping
      [+] No Firing Animations
      [+] Crosshair
      [+] Custom FOV
      [+] Zoom FOV(On Hotkey)
      
      Silent Mods
      [+] Auto Pickup
      [+] Keep Alive All(Keeps Downed Player Alive)(Forces Them To F1 Kill)
      [+] Instant Revive Teammates
      [+] Instant Revive Target
      [+] Auto Upgrade
      [+] Auto Heal
      [+] Follow Leader
      [+] Spinbot
      [+] Silent Melee
      [+] Silent Farm
      
      [ Misc ]
      
      [+] Admin Flags
      [+] Safe Admin Commands
      [+] Instant Loot
      [+] Suicide(Always On/On Hotkey with Spam Rate)
      [+] Interactive Debug
      [+] SteamID Copier
      [+] Toggle Friend
      [+] Streamproof
      
      [ Settings ]
      
      [+] Font Shadows
      [+] Font Distance Scaling
      [+] Custom Font
      [+] Custom ESP Colors
      
      Experimental
      [+] Radar
      [+] Raid ESP
      [+] Disable Nearby Recyclers
      [+] Disable Nearby Furnaces
      [+] Disable Nearby Campfires
      [+] Animations
      [+] Stash Unhider
      
      Object ESP
      [+] Ores
      [+] Collectibles
      [+] Animals
      [+] Helicopters
      [+] Vehicles
      [+] Boats
      [+] Construction
      [+] Crates
      [+] Barrels
      [+] Loot(Dropped Items, Corpses)
      [+] Custom Tab(You Can Add Custom Objects To ESP And Save Them To Your Config)`,
    status: 'Undetected' 
  },

  {
    id: 170334,
    name: 'Reported Apex',
    category: 'Apex',
    to: '/Product/170334',
    price: '$4.99',
    imageSrc: '/apexfull.png',
    desc:
      `▸ Aimbot, you can choose if you want to run it or not and select an aimkey for it.
      ▸ Randomized aim movement (no straight line aiming like 100% of all other aimbots do)
      ▸ Smoothing, to make your aim look more human
      ▸ Aimdelay, to slow down the aimsteps and again look more human
      ▸ Triggerbot (auto shoot if target is in crosshair), you can choose the trigger key
      ▸ No recoil, you can choose the amount of recoil reduction
      ▸ Visibility check
      ▸ Ignore downed
      ▸ Aim prediction
      ▸ Bone selection
      ▸ Lock target (or not)
      ▸ Controller support
      ▸ Optional second aim key
      ▸ 3rd Person view
      ▸▸▸ Automatically changes to normal view when you aim
      ▸ External overlay
      ▸▸▸ Streamproof
      ▸▸▸ Videorecord proof
      ▸▸▸ Screenshot proof
      ▸▸▸ Name ESP
      ▸▸▸ TEAM ESP
      ▸▸▸ TOTAL HP NUMBER (HEALTH + SHIELD)
      ▸▸▸ HP BAR
      ▸▸▸ SHIELD BAR
      ▸▸▸ DISTANCE ESP
      ▸▸▸ LINES TO ENEMIES
      ▸▸▸ BOX ESP
      ▸▸▸ BONE ESP
      ▸▸▸ AIM FOV CIRCLE
      ▸▸▸ TRIGGER FOV CIRCLE
      ▸▸▸ RENDER DISTANCE SLIDER
      ▸▸▸ FONT SELECTION
      ▸▸▸ FONT SIZE SELECTION
      ▸▸▸ RENDER REFRESH RATE SLIDER (can be lowered on slow computers)
      ▸▸▸ Visbility check`,
    status: 'Undetected'
  },
  {
    id: 170335,
    name: 'Reported Apex Glow',
    category: 'Apex',
    to: '/Product/170335',
    price: '$2.49',
    imageSrc: '/apexglow.png',
    desc:
      `▸ Glow with visibility check, you can select the color
      ▸▸▸ Friend color
      ▸▸▸ Enemy knocked color
      ▸▸▸ Enemy color + visible
      ▸▸▸ Enemy color + in-visible
      ▸▸▸ Enemy low hp + visible
      ▸▸▸ Enemy low hp + in-visible
      ▸ External Menu`,
    status: 'Undetected'
  },
  {
    id: 215342,
    name: 'Reported R6 lite',
    category: 'R6',
    to: '/Product/215342',
    price: '$1.99',
    imageSrc: '/ezkali.png',
    desc:
      `System requirements:

      AMD / INTEL CPU
      WINDOWS 10 ALL / WINDOWS 11 (sometimes does not work on windows 11 22h2)
      
      
      AIMBOT
      
      - Toggle
      - Smoothing
      - FOV
      - Hitbox
      - Aimkey
      
      VISUALS
      
      - Box
      - Name
      - Health
      - Outline
      - Skeleton
      - TeamID
      - Lines
      - Gadget
      
      MISC
      - No recoil
      - Outlines
      - No spread
      - No sky
      - Custom colors for FOV, lines, and skeleton
      - Config system`,
    status: 'Undetected'
  },
  {
    id: 253259,
    name: 'Reported R6 full',
    category: 'R6',
    to: '/Product/253259',
    price: '$4.99',
    imageSrc: 'https://storage.sell.app/store/37079/listings/3DSYZwFXwITaYb2YDOtlEUUqsyO7o51fTu8HYaZA.png',
    desc:
      `System requirements:

      AMD / INTEL CPU
      WINDOWS 10 ALL / WINDOWS 11 (sometimes does not work on windows 11 22h2)
      
      
      AIMBOT
      
      - Toggle
      - Smoothing
      - FOV
      - Hitbox
      - Aimkey
      
      VISUALS
      
      - Box
      - Name
      - Health
      - Outline
      - Skeleton
      - TeamID
      - Lines
      - Gadget
      
      MISC
      - No recoil
      - Outlines
      - No spread
      - No sky
      - Custom colors for FOV, lines, and skeleton
      - Config system`,
    status: 'Undetected'
  },
  
  // {
  //   id: 175962,
  //   name: 'Kool R6',
  //   category: 'R6',
  //   to: '/Product/175962',
  //   price: '$11.99',
  //   imageSrc: '/kool.png',
  //   desc:
  //     `[ AIM ]
  //     - Aim assist
  //     - Aim assist smoothing 
  //     - Draw FOV 
      
  //     [ ESP ]
  //     - Skeleton ESP
  //     - Health ESP
  //     - Toggle Teammates
  //     - Toggle Bots;
  //     - Visibility Check
  //     - Customizable Colors
      
  //     [ MISC ]
  //     - Status Icon
  //     - Streamproof (OBS, Streamlabs, etc)
  //     - HWID swoofer Built in`,
  //   status: 'Undetected'
  // },
  {
    id: 214414,
    name: 'Speedtail R6',
    category: 'R6',
    to: '/Product/214414',
    price: '$11.99',
    imageSrc: '/speedtailmonkey.png',
    desc:
      `System requirements:

      AMD / INTEL CPU
      WINDOWS 10 ALL / WINDOWS 11 (sometimes does not work on windows 11 22h2)
      
      
      AIMBOT
      
      - Toggle
      - Smoothing
      - FOV
      - Hitbox
      - Aimkey
      
      VISUALS
      
      - Box
      - Name
      - Health
      - Outline
      - Skeleton
      - TeamID
      - Lines
      - Gadget
      
      MISC
      - No recoil
      - Outlines
      - No spread
      - No sky
      - Custom colors for FOV, lines, and skeleton
      - Config system`,
    status: 'Undetected'
  },
  
  {
    id: 194668,
    name: 'Fecurity COD',
    category: 'COD',
    to: '/Product/194668',
    price: '$4.99',
    imageSrc: '/feccod.png',
    desc:
      `Fecurity
      Requirements
      
      ▸ CPU: INTEL and AMD
      ▸ Win Ver: 1909 - all new (Windows 10 only)(Home and Pro but better use Pro version)
      
      Aimbot
      Enabled Checkbox
      Type (Mouse, Silent) Types of aimbot
      Aim Key Keybind
      Hitbox key keybind
      Ignore Knocked Targets Checkbox
      Style (Bind, Scope, Attack)
      Switch Target Delay Slider ms
      NoRecoil Checkbox
      DrawFov Checkbox
      Prediction Checkbox
      Visible Only Checkbox
      Fov Slider
      Aim Speed Horizontal Slider
      Aim Speed Vertical Slider
      Hitscan
      
      Visuals
      Enabled Checkbox
      Enemy Only Checkbox
      Player Group:
      Visible Only Checkbox
      Auto Enemy Mark Checkbox
      Box Checkbox
      Box Type (Rect, Corner, Rounded Rect) Combobox
      Box Outline Checkbox
      Distance Checkbox
      Weapon Name Checkbox
      Health Bar Checkbox
      Armor Bar Checkbox
      Nicknames Checkbox
      Ping Checkbox
      Rank Checkbox
      Kills Checkbox
      Max Distance Slider
      GlowGroup:
      Glow Checkbox
      Fill Checkbox
      ColorVisible ColorPicker
      ColorInvisible ColorPicker
      Line Width Slider
      Mode (Default, Collapsed, Thermal)
      GlowGroup End
      PlayerGroup End
      LootGroup:
      Loot Enabled Checkbox
      Distance Checkbox
      Cache Delay Slider
      Max Distance Slider
      LootFilter:
      Weapons Checkbox
      Grenades Checkbox
      Ammo Checkbox
      Offhand Checkbox
      Armor Checkbox
      Killstreak Checkbox
      Money Checkbox
      Market Checkbox
      Contracts Checkbox
      LootFilter End
      LootGroup End
      
      Misc:
      Override Fov Checkbox
      Override Fov -> Value Slider
      Juggernaut UAV Checkbox
      Quiet steps Checkbox
      Adjustment Checkbox
      Slide Cancel Checkbox
      Unlocker Group:
      Loadout Future Checkbox
      Weapons Checkbox
      Blueprints Checkbox
      Charms Checkbox
      Stickers Checkbox
      Sights Checkbox
      Attachments Checkbox
      Operators Checkbox
      Replicas Checkbox
      Finishers Checkbox
      UnlockerGroupEnd
      EngineGroup
      Use animations for visuals Checkbox
      Animations speed multiplayer Slider
      DPI Scale -> Slider
      EngineGroupEnd
      Utils Group:
      Reload Fonts Button
      Reload Functions Button
      Detach Software Button
      UtilsGroupEng
      
      Colors
      
      Experimental
      Here we are developing new functions.`,
    status: 'Undetected'
  },
  
  {
    id: 53550,
    name: 'Reported Fortnite',
    category: 'Fortnite',
    to: '/Product/53550',
    price: '$5.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/JpFbQ8HwxIzXdMjoBiQpG8XZOJpqksc2BO0n7rr9.jpg',
    desc:
      `Works on all windows 10 and 11 versions (sometimes windows 11 22h2 has issues)


      AIMBOT
      
      - Toggle
      - Smoothing slider
      - FOV slider
      - Aimbot distance 
      - Aimkey
      - Hitbox
      
      VISUALS
      
      - Visible check
      - Box
      - Distance
      - Ammo
      - Outline
      - Skeleton
      - Team ID
      - Lines
      - Weapon name
      - Custom box styles
      - Custom lines style
      
      MISC
      
      
      - Configuration system
      - Custom coloring for visible
      - Custom coloring for invisible
      - Custom coloring for FOV circle`,
    status: 'Undetected'
  },
  {
    id: 172325,
    name: 'CS2 Pro',
    category: 'CS',
    to: '/Product/172325',
    price: '$11.99',
    imageSrc: '/cs2monkey.png',
    desc:
      `SYSTEM REQUIREMENTS

      [+] Windows 10 all versions
      [+] Windows 11 all versions except 22h2
      [+] AMD / INTEL CPUS
      
      
      AIM 
      
      
      [+] Enable
      [+] Prediction
      [+] Bone selection
      [+] FOV
      [+] FOV rate
      
      
      VISUALS
      
      - Player ESP
      
      [+] Enable
      [+] Box
      [+] Skeleton
      [+] Current item
      [+] Distance
      [+] Corpses
      
      - Infected ESP
      
      [+] Enable
      [+] Distance
      
      - Vehicle ESP
      
      [+] Enable
      [+] Distance
      
      - Animal ESP
      
      [+] Enable
      Distance
      
      - Item ESP
      
      [+] Enable
      [+] Distance
      [+] Quality
      [+] Weapon
      [+] Ammunition
      [+] Attachments
      [+] Clothes
      [+] Food and drinks
      [+] Medical supplies`,
    status: 'Undetected'
  },
  {
    id: 170333,
    name: 'Reported Spoofer',
    category: 'Spoofer',
    to: '/Product/170333',
    price: '$9.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/4f889e7ac19a59d1e6eb461b8c761ee0.png',
    desc:
      `BattlEye🟢
      🟢 ARK
      🟢 ARMA
      🟢 BLACK SQUAD
      🟢 DAYZ
      🟢 ESCAPE FROM TARKOV
      🟢  FORTNITE
      🟢 RAINBOW SIX SIEGE
      🟢 SUPER PEOPLE
      🟢  PUBG: BATTLEGROUNDS
      🟢 UNTURNED
      🟢 THE CYCLE: FRONTIER
      
      
      
      Easy Anti-cheat
      🟢 APEX LEGENDS
      🟢 VAMPIRE: THE MASQUERADE - BLOODHUNT
      🟢 DEAD BY DAYLIGHT
      🟢  FORTNITE
      🟢 SCUM
      🟡 RUST (possibly works for some people and not for others)
      
      
      
      Call of Duty
      🟢 MW2019
      🟢 Warzone
      🟢 Cold War
      
      
      Vanguard
      🟢Valorant (Most people) `,
    status: 'Undetected'
  },
  
  // {
  //   id: 65232,
  //   name: 'Fortnite Accounts',
  //   category: 'Account',
  //   to: '/Product/65232',
  //   price: '$10.00',
  //   imageSrc: 'https://storage.sell.app/store/3001/listings/ByMmGBypyWtvsxPByrJfnLwGZzBF7b1ReLAkAWbU.jpg',
  //   desc:
  //     `Here I will be selling FA accounts for fortnite`,
  //   status: 'Undetected'
  // },
  {
    id: 175210,
    name: 'EFT Accounts',
    category: 'Account',
    to: '/Product/65237',
    price: '$24.99',
    imageSrc: 'https://storage.sell.app/store/3001/listings/ghlPOgHHelJQiwuxOQof5B6h5zFU86Zx6zYkdtUr.jpg',
    url: "https://reportedhacks.sell.app/product/product-1696898550",
    desc:
      `Here I will be selling EFT accounts `,
    status: 'Undetected'
  },
  {
    id: 175210,
    name: 'Rust VPN',
    category: 'Account',
    to: '/Product/65238',
    price: '$19.99',
    url: "https://reportedhacks.sell.app/product/product-1696898550",
    imageSrc: 'https://storage.sell.app/store/3001/listings/xB1LcgK5vcyogT0eb8avVVxhqEhqdhfO9FXtUBqc.jpg',
    desc:
      `Here is a VPN you can use to bypass IP bans on almost all rust servers. You can also use it for normal VPN usage.`,
    status: 'Undetected'
  },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  let { id } = useParams();

  var productsDisplay = []
  products.map((product) => {
    if(product.category === id) {
      productsDisplay.push(product)
    }
  })
  return (
    <div>
      <div className="isolate bg-black h-full">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <main>
              <div className="mx-auto max-w-2xl text-center mt-12">
                <br />
                <br />
                <h2 className="text-4xl font-bold tracking-tight text-white">
                  <br />
                  Become Better Today.
                </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                If ya can't beat 'em, join 'em.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                </div>
              </div>
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                {productsDisplay.map((product) => (
                    <div key={product.id} className="group relative">
                    <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100">
                        <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" />
                        <div className="flex items-end p-4 opacity-0 group-hover:opacity-100" aria-hidden="true">
                        <div className="w-full rounded-md bg-white bg-opacity-25 py-2 px-4 text-center text-sm font-medium text-white backdrop-blur backdrop-filter">
                            View Product
                        </div>
                        </div>
                    </div>
                    <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-white">
                        <h3>
                        <Link to={product.to}>
                            <span aria-hidden="true" className="absolute inset-0" />
                            {product.name}
                        </Link>
                        </h3>
                        <p>{product.price}</p>
                    </div>
                    <p className="mt-1 text-sm text-gray-300">{product.category}</p>
                    </div>
                ))}
                </div>
            </div>
        </main>
      </div>
      <footer className="bg-black">
        <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
          <br />
            <p className="text-center text-xs leading-5 text-gray-300">
              &copy; © 2024 Reported Cheats, Inc. All rights reserved.
            </p>
          </div>
        </div>
    </footer>
    </div>
  )
}
