import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const topnavigation = [
  { name: 'Cheats', to: '/cheats' },
  { name: 'TOS', to: '/tos' },
  { name: 'Status', to: '/status' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Discord', to: '/discord' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  return (
    <div>
      <div className="isolate bg-black">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#f4773080-2a16-4ab4-9fd7-579fec69a4f7)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="f4773080-2a16-4ab4-9fd7-579fec69a4f7"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="px-6 pt-6 lg:px-8">
          <nav className="flex items-center justify-between" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Reported Cheats</span>
                <img className="h-12" src="/reported.png" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {topnavigation.map((item) => (
                <Link key={item.name} to={item.to} className="text-sm font-semibold leading-6 text-white hover:text-fuchsia-500">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel focus="true" className="fixed inset-0 z-10 overflow-y-auto bg-black px-6 py-6 lg:hidden">
              <div className="flex items-center justify-between">
                <Link to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Reported Cheats</span>
                  <img className="h-12" src="/reported.png" alt="" />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {topnavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-400/10"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        </div>
        <div className="overflow-hidden bg-black">
      <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-black lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-fuchsia-500">Reported Cheats</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-300 sm:text-4xl">Terms of Service</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-fuchsia-500" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg ml-10"
                    src="/privvy.png"
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
                <figcaption className="mt-3 flex text-sm text-fuchsia-500">
                  <Link to="/product/24226"className="ml-12">EFT Private</Link>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-white">
              It is your responsibility to follow the system-Requirements to run our services.
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
              It is your responsibility to make sure to read over all information, such as system-requirements, status, and how to run the software.
              </p>
              <p>
              We can take as long as we want when processing an order and may take any fee we want while processing or refunding the order.
              </p>
              <p>
              Please make sure to always read The status of the software.
              </p>
              <p>
              We are not responsible for any bugs/errors/bans that you receive with the software's, but we will be sure to help you with any.
              </p>
              <p>
              Any type of Fraud, Fraudulent, Identity Theft, Stolen Debit/Credit cards, are to be permitted by viewing, purchasing, and using on our website! Fraudulent orders will be banned and a refund will not be given.
              </p>
              <p>
              After we have given you your order we can not do anything about getting your money back, the only exception for you getting your money back is if your order has not been completed. Once your goods are sent to you, any money is impossible to get back. Even so we do not have to give you a refund if we think that it is a customer based error.
              </p>
              <p>
              We are not responsible if your subscription gets removed/loss OR any type of loss of your account happens.
              </p>
              <p>
              Lifetime means lifetime of the product not your lifetime. We will not be developing cheats our entire lives. Though, lifetime is a good option for long term cheating for a couple years more than likely. Until I get a hot girlfriend and move to Dubai, the cheat will most likely be up. 
              </p>
              <p>
              [CHARGEBACK/FRAUDULENT PAYMENTS] We keep a close relationship with the staff from the various software providers that we sell for; thus, if you are found to have manipulated or charge-backed payments or transactions in any way, you will be banned from all software providers we sell for. Your payment information and email will be shared with other resellers and service/software providers dis-allowing you from accessing softwares on various websites and communities. The same also applies to fraudulent payments with stolen cards or false/stolen identities. You have been warned.
              </p>
              <p>
              [REFUND POLICY/WARRANTY] By purchasing products (license keys, codes, electronic gift card redemption codes etc.,), you accept that there will be no refunds under any circumstances. We are not working for the software/service providers; we merely sell for them. As such, it is your responsibility to ensure that the system and software pre-requisites defined by the service providers are met. Furthermore, any transactions of cryptocurrency are void of all warranty, no refunds allowed. ALL SALES ARE FINAL AFTER PURCHASING!! We Will not refund your money at all!
              </p>
              <p>
              [BLACKLIST RULES] Any disputes will result in an automatic blacklist on my site. As said before, there are no refunds if the product has been delivered. Need help with setting something up? Make a ticket. You will need to pay for an unblacklist
              </p>
              <p>
              [ACCOUNTS] For accounts, we will provide you with the full access email / fowarding email depending on what type of account you purchased. However, after we have validaded that the login is correct there is no refunds at all. 
              We are not responsible for the account being "taken back" or the account password / user changing after we have sent you your account. Even if you purchased a full access account, we gave you full access to the email, however that 
              does not exactly mean its fresh / unused. 
              
              </p>
            
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-300">
            &copy; 2024 Reported Cheats. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    </div>
  )
}
